/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';

import { sha256 } from 'js-sha256';
import { SettingsService } from '../settings/settings.service';
import { HttpParams } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
  FileUploadResult
} from '@ionic-native/file-transfer/ngx';
//import { File } from '@ionic-native/file';
import { File } from '@ionic-native/file/ngx';

import { Booking } from '../../models/booking';
import { FcmMessage } from 'src/app/models/fcmmessage';

@Injectable({
  providedIn: 'root'
})
export class ApiService {



  constructor(public http: HttpClient, public sett: SettingsService, private fileTransfer: FileTransfer ) { }

  login(username: string, password: string): Observable<LoginResponse> {
    //?resource=12-Dragan&sender=21956'
    const pwd = sha256(password);

    const url =this.sett.REST_URL + '/Mobile/Login?UserName=' + username + '&PassWord=' + pwd + '&version=' + this.sett.GetLoginVersion();

    //return this.http.get(url).map(res => res.json());
    //return this.http.get<LoginResponse>(url);
    return this.http.get<LoginResponse>(url);
  }

  getInstances():Observable<any> {
    const url = this.sett.instanceApiUrl;
    return this.http.get(url);
  }

  getBookingData(resource: string, sender: string): Observable<any> {
    //?resource=12-Dragan&sender=21956'
    const url = this.sett.REST_URL + '/Sodexo/MobileGetOrderList?resource=' + resource +'&sender=' +sender;

    //return this.http.get(url).map(res => res.json());
    return this.http.get(url);
  }

  getFcmMessages(resource: string, sender: string): Observable<FcmMessage[]> {
    const url =
      this.sett.REST_URL +
      '/Mobile/GetFcmMessages?resource=' +
      resource +
      '&sender=' +
      sender;

    return this.http.get<FcmMessage[]>(url);
  }

  sendEvent(
    sender: string,
    id: string,
    command: string,
    msg: string
  ): Observable<any> {
    const url = this.sett.REST_URL + '/Mobile/MobileSyncJson';
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    // let p = new HttpParams()
    //   .set("sender", sender)
    //   .set("id", id)
    //   .set("command", command)
    //   .set("message", msg)
    //   .set("mobilesyncdate", new Date().toISOString());
    const p = new HttpParams()
      .set('sender', sender)
      .set('id', id)
      .set('command', command)
      .set('message', msg)
      .set('mobilesyncdate', new Date().toISOString());
    //var postMsg = { sender: sender, id: o.id, command: o.command, message: o.msg, mobilesyncdate: new Date().toISOString() };
    console.log(p);
    console.log(p.keys());

    return this.http.post(url, '', { params: p });
  }

  TransferFile(fileURL: string): Promise<FileUploadResult> {
    const phoneMode = true;

    if (phoneMode) {
      const options: FileUploadOptions = {
        fileKey: 'file',
        fileName: fileURL.substr(fileURL.lastIndexOf('/') + 1),
        chunkedMode: false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { Connection: 'close' },
        params: { value1: 'test', value2: 'param' }
      };
      const url = this.sett.REST_URL + '/Mobile/uploadfile';

      const ft = new FileTransfer().create();
      // ft.upload(fileURL, encodeURI(url), options, true).then(
      //   data => {
      //     // success
      //     console.log("File transfer success");
      //     if (this.sett.DEBUG_MODE) alert("File transfer OK!");
      //   },
      //   reason => {
      //     //Rejected
      //     console.log("Rejected");
      //     console.log(reason);
      //     if (this.sett.DEBUG_MODE)
      //       alert("File transfer rejected:" + JSON.stringify(reason));
      //   }
      // );


      return ft.upload(fileURL, encodeURI(url), options, true);
      //FileTransfer.upload(fileURL, encodeURI(url), win, fail, options, true);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public PostScans(
    barcodeList: string[],
    eventtypename: string,
    terminalId,
    resource
  ): Observable<any> {
    //TODO: Gör en mobile version av denna
    const url =
      this.sett.REST_URL.replace('/mobile', '') + '/public/postjobitemscans';

    const po = {
      customer: null,
      barcodes: barcodeList,
      terminalid: terminalId,
      eventtype: eventtypename,
      resource
    };

    const p = new HttpParams().set('obj', JSON.stringify(po));

    return this.http.post(url, '', { params: p });
  }

  public UpdatePosition(GeoX: number, GeoY: number, resource: string) {
    const url = this.sett.REST_URL + '/Mobile/UpdatePosition';
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const p = new HttpParams()
      .set('resource', resource)
      .set('x', GeoX.toString())
      .set('y', GeoY.toString());

    return this.http.post(url, '', { params: p });
  }
}

export interface SettingsKeyValue {
  key: string;
  value: string;
}

export interface LoginResponse {
  Error: string;
  NewVersionUrl: string;
  SessionId: string;
  ResourceList: string;
  FullName: string;
  Terminals: string;
  Settings: [SettingsKeyValue];
  Permissions: [string];
}
