import { Injectable } from '@angular/core';
import { BarCodeCollection } from "../../models/barcodecollection";

@Injectable({
  providedIn: 'root'
})
export class BarcodecollectionService {

  constructor() {
    console.log("Hello BarCodeCollectionProvider Provider");
    this.barcodeCollections = [];
    var x = localStorage.getItem("barCodeCollections");
    if (x) {
      this.barcodeCollections = JSON.parse(x);
      this.CleanCollectionFromOld();
    }
   }


  barcodeCollections: BarCodeCollection[];

  public GetCollectionFromName(name: string) {
    //Check subCodes
    return this.barcodeCollections.find(x => {
      return x.barCode == name
    });
  }


  public GetCollection(barCode: string) {
    //Check subCodes
    return this.barcodeCollections.find(x => {
      return x.barCode == barCode || x.subCodes.indexOf(barCode) >= 0;
    });
  }

  public GetCollections(): BarCodeCollection[] {
    return this.barcodeCollections;
  }

  public SetCollection(bcc: BarCodeCollection) {
    bcc.created = new Date();

    var idx = this.barcodeCollections.findIndex(x => {
      return x.barCode == bcc.barCode || x.subCodes.indexOf(bcc.barCode) >= 0;
    });
    if (idx >= 0) this.barcodeCollections[idx] = bcc;
    else this.barcodeCollections.push(bcc);

    this.SaveCollectionToStorage();
  }

  public RemoveCollection(bcc: BarCodeCollection) {
    var idx = this.barcodeCollections.findIndex(x => {
      return x.barCode == bcc.barCode || x.subCodes.indexOf(bcc.barCode) >= 0;
    });
    if (idx >= 0) this.barcodeCollections.splice(idx, 1);
  }

  private SaveCollectionToStorage() {
    //this.CleanCollectionFromOld();
    localStorage.setItem(
      "barCodeCollections",
      JSON.stringify(this.barcodeCollections)
    );
  }

  public CleanCollectionFromOld() {
    var t = new Date().valueOf();
    var minVal = t - 3600 * 24 * 1000;
    this.barcodeCollections = this.barcodeCollections.filter(x => {
      return new Date(x.created).valueOf() > minVal;
    });
  }

  public ExpandBarCodeList(barcodeList: string[]): string[] {
    var res: string[] = [];
    barcodeList.map(x => {
      var coll = this.GetCollection(x);
      if (coll)
        coll.subCodes.forEach(sc => {
          if (res.findIndex(s => s == sc) < 0)
            res.push(sc);
        });
      else {
        if (res.findIndex(s => s == x) < 0)
          res.push(x);
      }
    });
    return res;
  }
}
