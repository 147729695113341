import { Injectable } from '@angular/core';
//import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() {     
    if(!this.REST_URL) {
      const inst = localStorage.getItem('instance');
      if(inst)
        {
          const l = JSON.parse(inst);
          this.REST_URL = l.url;
        }
      // this.RefreshInstances();
    }       
  }

  // public RefreshInstances() {
  //   this.http.get<InstanceDefinition[]>(this.instanceApiUrl).forEach(x=> {
  //     this.Instances = x;
  //   });    
  // }

  public GetInstance() {
    if(this.REST_URL) {
      return this.Instances.find(x => x.url === this.REST_URL);
    }
    return null;
  }

  public SetInstance(instance) {
    let b = null;
    this.REST_URL = null;
    if(instance)
      {b = JSON.stringify(instance);
      this.REST_URL = instance.url;
      }
    localStorage.setItem('instance', b);

  }

  public DEBUG_MODE = false;

  public Version = 'TP_SODEXO_2023-11-27'; 
  public WelcomeText = 'Välkommen till Transplanet Sodexo';
  public VersionControl = false;

  public instanceApiUrl = 'https://sodexoappsim.logivia.se/instances/instances.json';
  public Instances:InstanceDefinition[] = [
    {"name": "(Laddar...)", "url": "" },
    //{"name": "Stockholm", "url": "https://sodexosthlm.logivia.se" }, 
    // {"name": "Gävleborg", "url": "https://sodexogvb.logivia.se"}, 
    // {"name": "Skåne 10-gruppen", "url": "https://sodexoskane.logivia.se"}, 
    // {"name": "Test", "url": "https://sodexotest.logivia.se", "hidden": true},    
    // {"name": "Test Gvb", "url": "https://sodexogvbtest.logivia.se"}, 
    // {"name": "Lokal", "url": "http://localhost:60462", "hidden": true}
  ];
  // [
  //   {name: 'Stockholm', url: 'https://sodexosthlm.logivia.se' }, 
  //   {name: 'Gävleborg', url: 'https://sodexogvb.logivia.se'}, 
  //   {name: 'Test', url: 'https://sodexotest.logivia.se', hidden: true},    
  //   {name: 'Test Gvb', url: 'https://sodexogvbtest.logivia.se'}, 
  //   {name: 'Lokal', url: 'http://localhost:60462', hidden: true},    
  // ];

  public REST_URL = null;

  //REST_URL = "http://localhost:60462";
  //REST_URL = "https://sodexotest.logivia.se";
  //public VersionControl = false;

  public UpdatePositionIntervalSecs = 120;



  public GetLoginVersion() {
    if (this.VersionControl)
      return this.Version;

    return "NoControl";
  }
}

export class InstanceDefinition {
  name:string;
  url:string;
  hidden?:boolean;
}

