import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NavtopComponent } from './components/navtop/navtop/navtop.component';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { RegisterButtonsComponent } from './components/RegisterButtons/register-buttons/register-buttons.component';
//import { RegisterButtonsModule } from './components/RegisterButtons/register-buttons-component-module';
import { ScanCompComponent } from './components/scan-comp/scan-comp/scan-comp.component';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@NgModule({
  declarations: [
    AppComponent,
    NavtopComponent,
    RegisterButtonsComponent,
    //RegisterButtonsModule,
    ScanCompComponent
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule   ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    FileTransfer,
    FileTransferObject,
    BarcodeScanner,
    NavParams,
    NavtopComponent,
    RegisterButtonsComponent,
    //RegisterButtonsModule,
    ScanCompComponent,
    Geolocation,
    Keyboard,
    LaunchNavigator,
    Camera,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
