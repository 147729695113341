import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fromEvent, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { Platform } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { BarcodecollectionService } from 'src/app/services/barcodecollection/barcodecollection.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'scan-comp',
  templateUrl: './scan-comp.component.html',
  styleUrls: ['./scan-comp.component.scss'],
})
export class ScanCompComponent implements OnInit {

  ngOnInit() {}
  @Input() barcodeList: string[];
  @Input() barcodeDetails: {};
  @Input() allowedLengths: string = "6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21";
  @Input() noCheck: boolean = false;

  lastBarCode: string;
  vwonBarCodeChange = 0;
  vwNewBarcode = 0;
  //barcodeList: string[] = [];

  errorMsg: string;
  public barCode?: string;
  lastTimeChecked: number;
  //allowedLengths: string = "6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21";
  log: string[] = [];
  disableCancel: boolean; //Disable possibility to leave page

  @Output()
  BarcodeListChanged = new EventEmitter<string[]>();
  @Output()
  NewBarCodeAdded = new EventEmitter<string>();

  constructor(
    private keyboard: Keyboard,
    private barcodeScanner: BarcodeScanner,
    platform: Platform,
    public settings: SettingsService,
    public bcc: BarcodecollectionService
  ) {
    this.barCode = "";
    //this.barcodeList = [];

    interval(this.CHECK_INTERVAL)
      // .takeWhile(() => true)
      .subscribe(i => {
        //console.log('Barcodecheck');
        this.NewBarcode();
      });

    keyboard.onKeyboardHide().subscribe(k => {
      this.AddToLog("Keyboard close");
      //keyboard.show();
    });

    fromEvent(document, "keypress").subscribe(e => {
      let ev: any = e;
      let k: any = ev.key.toString().toUpperCase();
      console.log(e);
      this.AddToLog(k);
      if (k == "ENTER") {
        //this.NewBarcode(true);
        return;
      }
      //TODO: Filter allowed characters
      this.barCode += k;
    });

    platform.backButton.subscribe((x)=> {
      console.log("Backbutton");
      if (this.disableCancel) {
        //Do nothing
      } else {
        //this.Cancel();
        //alert("Avbryt");
      }
    })    
  }

  NEW_BARCODE_DELAY: number = 410;
  CHECK_INTERVAL = 200;
  DELAY_AFTER_CAMERA = 700;
  CAMERA_DELAY = 500;

  eventTracker = [
    { name: "keyup", enabled: false, count: 0 },
    { name: "keydown", enabled: false, count: 0 },
    { name: "keypress", enabled: false, count: 0 },
    { name: "input", enabled: true, count: 0 },
    { name: "change", enabled: false, count: 0 }
  ];

  public onEvent(event, name) {
    console.log("Event:" + name);
    var e = this.eventTracker.find(t => t.name == name);
    if (e == null) {
      console.log(name + " is not defined.");
      return;
    }
    e.count++;
    if (e.enabled) this.onBarCodeChange(event);
  }
  onBarCodeChange(ev) {
    this.vwonBarCodeChange++;

    console.log("Changed:" + this.barCode);
    this.AddToLog("onBarCodeChange:" + this.barCode);
    this.lastTimeChecked = new Date().getTime();
  }

  ValidateBarcode(barcode): string {
    if (this.noCheck)
      return "";

    var bc = barcode.trim().toUpperCase();
    if (this.bcc.GetCollectionFromName(bc))
      return ""; //OK!

    if (this.allowedLengths != "" && this.allowedLengths != undefined) {
      var lengths = this.allowedLengths.split(",");
      var l = bc.length;
      if (lengths.indexOf(l.toString()) < 0) {
        return "Felaktig längd.";
      }
    }

    return "";
  }

  EnterBarCodeManually(text = "Streckkod", initVal = "") {
    this.barCode = "";

    var bc = prompt(text, initVal);
    if (bc != null) {
      bc = bc.toUpperCase();
      if (this.ValidateBarcode(bc) != "")
        this.EnterBarCodeManually("Ogiltig, försök igen:", bc);
      else {
        this.barCode = bc;
        this.NewBarcode(true);
      }
    }
    //document.focus();
  }

  RemoveFromBarCodeList(b: string) {
    var index = this.barcodeList.indexOf(b);
    this.barcodeList.splice(index, 1);
    this.BarcodeListChanged.emit(this.barcodeList);
  }

  // public SetBarCodeDetail(bcd: BarCodeDetail) {
  //   if (!this.barCodeDetails) this.barCodeDetails = {};
  //   alert(bcd);
  //   console.log(bcd);
  //   this.barCodeDetails[bcd.barcode] = bcd;
  // }
  public GetBarCodeDetail(bc: string): BarCodeDetail {
    if (!this.barcodeDetails || !this.barcodeDetails[bc])
      return new BarCodeDetail();

    return this.barcodeDetails[bc];
  }

  NewBarcode(forced: boolean = false) {
    //TODO: Kolla dubletter
    this.vwNewBarcode++;
    if (!this.barCode) {
      return; //Just wait
    }
    var bc = this.barCode.trim();

    if (!forced) {
      if (!this.lastTimeChecked) this.lastTimeChecked = new Date().getTime();

      let newTime: number = new Date().getTime();
      let timeDiff: number = newTime - this.lastTimeChecked;
      if (timeDiff < this.NEW_BARCODE_DELAY) {
        return; //Just wait
      }
      this.AddToLog("NewBarCode - lastTimeChecked changed");
      this.lastTimeChecked = new Date().getTime();
      //Avoid cut when typing
      if (bc != this.lastBarCode) {
        if (bc.length > 20) bc = ""; //Restart
        this.barCode = bc;
        this.lastBarCode = bc;
        return;
      }
    }
    console.log("Last barcode:" + this.lastBarCode + "->" + bc);

    if (!this.noCheck) {
      bc = bc.replace(/[^A-Za-z0-9åäöÅÄÖ_]/g, "");
      // if (bc.length < 5) {
      //   this.lastTimeChecked = new Date().getTime();
      //   return;
      // }
      //Special fix for Schenker
      if (bc.match(/\d\d...\d\d\d\d\d\d\d\d\d\d\d\d/) != null) {
        bc = bc.substr(7);
      }

    }


    this.errorMsg = this.ValidateBarcode(bc);
    if (this.errorMsg != "") return;
    if (!this.barcodeList) this.barcodeList = []; //Should not be needed
    if (this.barcodeList.indexOf(bc) >= 0) {
      this.errorMsg = "'" + bc + "' är redan skannad.";
      this.barCode = "";
      return;
    }

    this.barcodeList.push(bc);
    this.barCode = "";
    this.BarcodeListChanged.emit(this.barcodeList);
    this.NewBarCodeAdded.emit(bc);
  }

  AddToLog(s: string) {
    const MAXLOGLENGTH = 50;
    this.log.unshift(s);
    if (this.log.length > MAXLOGLENGTH) this.log.splice(MAXLOGLENGTH);
  }
  clearLog() {
    this.log = [];
  }

  startCameraFlag = true;

  public SetBarCodeList(list: string[]) {
    this.barcodeList = [];
    list.forEach(x => {
      console.log("Lägger till " + x);
      this.barcodeList.push(x);
    });
    console.log(this.barcodeList);
  }

  CameraScan() {
    let scanOptions: BarcodeScannerOptions = {
      //preferFrontCamera: true, // iOS and Android
      //showFlipCameraButton: true, // iOS and Android
      showTorchButton: true, // iOS and Android
      //torchOn: true, // Android, launch with the torch switched on (if available)
      prompt:
        "Sikta på streckkoden. Tryck tillbakapil (vänsterknapp) när du är klar.", // Android
      resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      //formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
      orientation: "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
      disableAnimations: true, // iOS
      disableSuccessBeep: false // iOS
    };
    this.barcodeScanner.scan(scanOptions).then(
      result => {
        var barCode = result.text;
        if (barCode != "" && !result.cancelled) {
          this.barCode = barCode;
          this.NewBarcode(true);

          //setTimeout(this.CameraScan, 500);
          //setTimeout does not work properly with Angular
          this.startCameraFlag = true;
          interval(this.CAMERA_DELAY)
            .pipe(takeWhile(() => this.startCameraFlag))
            .subscribe(i => {
              this.startCameraFlag = false;
              this.CameraScan();
            });
        }
        if (result.cancelled) {
          this.disableCancel = true;
          setTimeout(function () {
            this.disableCancel = false;
          }, this.DELAY_AFTER_CAMERA);
        }
      },
      err => {
        alert("Ett fel uppstod vid skanningen.");
        // An error occurred
      }
    );
  }
}


export class BarCodeDetail {
  public barcode: string;
  public valid: boolean;
  public msg: string;
}
