import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    //redirectTo: 'folder/Inbox',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'pages/scan',
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'pages/lab',
    loadChildren: () => import('./pages/lab/lab/lab.module').then( m => m.LabPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'collection-editor',
    loadChildren: () => import('./pages/CollectionEditor/collection-editor/collection-editor.module')
      .then( m => m.CollectionEditorPageModule)
  },
  {
    path: 'resource-select',
    loadChildren: () => import('./pages/resource-select/resource-select/resource-select.module').then( m => m.ResourceSelectPageModule)
  },
  {
    path: 'queue',
    loadChildren: () => import('./pages/queue/queue/queue.module').then( m => m.QueuePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/list/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'message',
    loadChildren: () => import('./pages/message/message/message.module').then( m => m.MessagePageModule)
  },
  {
    path: 'registerevent',
    loadChildren: () => import('./pages/registerevent/registerevent/registerevent.module').then( m => m.RegisterEventPageModule)
  },
  {
    path: 'booking-details',
    loadChildren: () => import('./pages/BookingDetails/booking-details/booking-details.module').then( m => m.BookingDetailsPageModule)
  },
  {
    path: 'list-reorder',
    loadChildren: () => import('./pages/ListReorder/list-reorder/list-reorder.module').then( m => m.ListReorderPageModule)
  },
  {
    path: 'bar-code-collection',
    loadChildren: () => import('./pages/bar-code-collection/bar-code-collection/bar-code-collection.module')
      .then( m => m.BarCodeCollectionPageModule)
  },
  {
    path: 'date-select',
    loadChildren: () => import('./pages/date-select/date-select/date-select.module').then( m => m.DateSelectPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
