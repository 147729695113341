import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Booking } from 'src/app/models/booking';
import { RegisterEventPage } from 'src/app/pages/registerevent/registerevent/registerevent.page';
import { BookingEventType, BookingService } from 'src/app/services/booking/booking.service';

@Component({
  selector: 'register-buttons',
  templateUrl: './register-buttons.component.html',
  styleUrls: ['./register-buttons.component.scss'],
})
export class RegisterButtonsComponent implements OnInit {
  @Input() bookings: Booking[];
  @Output() OnRegistered = new EventEmitter<BookingEventType>();

  constructor(public bookingsProvider: BookingService, public modalCtrl: ModalController) {
    console.log('Hello RegisterButtonsComponent Component');

  }

  ngOnInit() {}

  GetEventTypesForSelected(): BookingEventType[] {
    return this.bookingsProvider.GetEventTypesForBookings(this.bookings);
  }

  public async RegisterSelected(et: BookingEventType) {
    console.log('Register pressed' + et.name);
    
    if (this.bookings.length == 0) //Should not be possible
    {
      alert('Finns inga order valda.');
      return;
    }

    var mustScanTspDocNo = (this.bookings.findIndex(x => et.MustScanTspDocNo(x)) >= 0);
    var checkMsg = this.bookingsProvider.CheckBookingsBeforeEvent(this.bookings, et);
    if (checkMsg.length > 0) {
      var msg = checkMsg.map((x) => x.message).join('\n\n');
      if (checkMsg.find((x) => x.isSevere)) {
        alert(msg);
        return;
      }
      msg += '\n--------------\nVill du fortsätta registreringen ändå?';
      if (!confirm(msg))
        return;
    }

    const modal = await this.modalCtrl.create({
      component: RegisterEventPage,
      // eventTypeName: et.name,
      // eventTypeId: et.eventTypeId,
      // place: et.place,
      componentProps: {
      eventtype: et,
      bookings: this.bookings,
      mustScanTspDocNo: mustScanTspDocNo
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data != null && data.OK == "true") {
      if (et.newStatusId)
        this.bookings.forEach((x) => x.StatusId = et.newStatusId);

      //Do this on the client side
      //this.ClearSelection();
      this.OnRegistered.emit(et);
      //this.booking.StatusId = newStatusId;
    }

  }


}
